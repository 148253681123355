import "./FooterStyles.css";

const Footer = () => {
    return (
        <div className="footer">
            <div className="top">
            </div>

            <div className="bottom">
                <div>
                    <h1>Pure Glow Med Spa</h1>
                    <p>Begin your beautiful transformation at Pure Glow.</p>
                    <a href="https://www.instagram.com/pure_glow_medical_spa/">
                        <i className="fa-brands fa-instagram" style={{color: "#ffffff",}}></i>
                    </a>
                    <a href="https://www.tiktok.com/@pureglowmedspa1/">
                        <i className="fa-brands fa-tiktok" style={{color: "#ffffff",}}></i>
                    </a>
                    <a href="https://www.yelp.com/biz/pure-glow-medspa-montrose">
                        <i className="fa-brands fa-yelp" style={{color: "#ffffff",}}></i>
                    </a>
                </div>
                <div>
                    <h4>Our Location</h4>
                    <p>2621 Honolulu Ave Montrose, CA 91020</p>
                    <p>818-930-7775</p>
                    <p>Pureglowmedspa2023@gmail.com</p>
                </div>
            </div>
        </div>
    )
}

export default Footer