import { Component } from "react"
import "./ServicesStyles.css";
import { Link } from "react-router-dom"

class ServicesData extends Component {
    render() {
        return(
            <div className={this.props.className}>
            <div className="des-text">
            <Link to="/services" style={{ textDecoration: 'none', color: 'black'}}><h2>{this.props.heading}</h2></Link>
                <ul>
                    <Link to="/services" style={{ textDecoration: 'none', color: 'black'}}><li>{this.props.text}</li></Link>
                    <Link to="/services" style={{ textDecoration: 'none', color: 'black'}}><li>{this.props.text2}</li></Link>
                    <Link to="/services" style={{ textDecoration: 'none', color: 'black'}}><li>{this.props.text3}</li></Link>
                    <Link to="/services" style={{ textDecoration: 'none', color: 'black'}}><li>{this.props.text4}</li></Link>
                    <Link to="/services" style={{ textDecoration: 'none', color: 'black'}}><li>{this.props.text5}</li></Link>
                    <Link to="/services" style={{ textDecoration: 'none', color: 'black'}}><li>{this.props.text6}</li></Link>
                    <Link to="/services" style={{ textDecoration: 'none', color: 'black'}}><li>{this.props.text7}</li></Link>
                </ul>
            </div>

            <div className="image">
            <img alt="img" src={this.props.img1}/>
            </div>
        </div>
        )
    }
}

export default ServicesData;