import LocationData from "./LocationData"
import "./LocationStyles.css"

const Location = () => {
  return (
    <div className='location'>
        <h1>Location</h1>
        <p>Where we are located at</p>

      <LocationData
      className="first-des"
      heading="Pure Glow Medical Spa"
      text = "2621 Honolulu Ave Montrose, CA 91020"
      text2 = "818-930-7775"
      text3 = "pureglowmedspa2023@gmail.com"
      />

      <LocationData
      className="first-des"
      heading = "Hours of Operation"
      text = "Mon: Closed"
      text2 = "Tues-Sun: 9:00 AM - 5:00 PM"
      />

    </div>
  )
}

export default Location;