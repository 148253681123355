import { Component } from "react"
import "./LocationStyles.css";

class LocationData extends Component {
    render() {
        return(
            <div className={this.props.className}>
                <div className="des-text">
                    <h2>{this.props.heading}</h2>
                    <p>{this.props.text}</p>
                    <p>{this.props.text2}</p>
                    <p>{this.props.text3}</p>
                </div>
            </div>
        )
    }
}

export default LocationData;