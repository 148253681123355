import React from 'react'
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Hero from "../components/Hero"
import Banner from "../assets/Pure-Glow-Logo-White-Cropped-removebg-preview.png"
import Descriptions from '../components/Descriptions';

function Description(){
  return (
    <>
      <Navbar />
      <Hero
      cName="hero-mid"
      heroImg={Banner}
      title="Pure Glow's Services"
      url="/service"
      />
      <Descriptions />
      <Footer/>
    </>
  )
}

export default Description