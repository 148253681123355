import React from 'react'
import "./Descriptions.css"

const Descriptions = () => {
  return (
    <div className='descriptions'>
        <div className='skin-treat'>
            <h2 className='service-title'>Skin Treatments <i class='bx bx-face' ></i></h2>
            <h3 className='service-head'> Morpheus8</h3>
            <div className="serv-desc">
                <p className="service-desc">combines radio frequency and microneedling to contour and remodel the face and body. Increased collagen and elastin production stimulated by Morphesus . Improve the appearance of fine lines and wrinkles, reduce the appearance of large pores, hyperpigmentation, acne, scarring, tightens skin sagging, improves worsening or aging skin texture, reduces double chin, eliminates neck lines, improves dark circles and eyebags around your eyes.</p>
            </div>
            <h3 className='service-head'>Microneedling</h3>
            <div className='serv-desc'>
                <p className="service-desc">is a minimally invasive cosmetic procedure that uses small needles to prick the skin.  The purpose of this treatment is to generate new collagen and skin tissue to smooth, firm, and tone skin. Microneedling is mostly used on the face and treats scars, wrinkles, acne and large pores.</p>
            </div>
            <h3 className='service-head'>Microdermabarasion</h3>
            <div className='serv-desc'>
                <p className="service-desc">procedure uses a special applicator with abrasive surface to gently remove dead skin cells. It can improve the appearance of sun damage, wrinkles, acne, and scaring, fine lines.</p>
            </div>
            <h3 className='service-head'>Deluxe facial</h3>
            <div className='serv-desc'>
                <p className="service-desc">treatment includes cleaning, steaming, exfoliating, extraction, LED light therapy, serum application, hydrating, and mask. Multi-step process will improve tighten and hydrate the face.</p>
            </div>
            <h3 className='service-head'>Signature facial</h3>
            <div className='serv-desc'>
                <p className="service-desc">treatment includes cleaning, steaming, exfoliating, extraction, LED light therapy, serum application, RF facial, hydrating, and mask. Multi-step process will improve stimulate collagen and elastin tighten and hydrate the face.</p>
            </div>
            <h3 className='service-head'>Hydro Stamp</h3>
            <div className='serv-desc'>
                <p className="service-desc">is a skin rejuvenation treatment, injections of vitamins, fillers, Botox, PRP into the skin to rejuvenate and tighten skin. The purpose of this treatment is reducing fine lines and wrinkles, minimize pores, smooth skin texture, plump thin skin.</p>
            </div>
            <h3 className='service-head'>Plasma Fibrobla</h3>
            <div className='serv-desc'>
                <p className="service-desc">treatment is a nonsurgical skin-tightening procedure that uses a high energy discharge to make a small wound in the skin. This can encourage cells known as fibroblasts to repair the skin and maintain firmness.</p>
            </div>
        </div>

        <br/>
        <br/>
        <hr/>
        <br/>
        <br/>

        <div className='body-treat'>
            <h2 className='service-title'>Body Treatments <i class='bx bx-body' ></i></h2>
                <h3 className='service-head'>Ultrasonic Cavitation/Cavi Lipo</h3>
            <div className='serv-desc'>
                <p className="service-desc">is the use of ultrasound technology to break down fat cells below the skin. It is a non-surgical method of reducing cellulite and localized fat.</p>
            </div>
                <h3 className='service-head'>EMSculpt</h3>
            <div className='serv-desc'>
                <p className="service-desc">is the only procedure to help both women and men build muscle and sculpt their body. It reduces fat, tightens and tones the body.</p>
            </div>
        </div>

        <br/>
        <br/>
        <hr/>
        <br/>
        <br/>

        <div className='Injections'>
            <h2 className='service-title'>Injections <i class='bx bx-injection'></i></h2>
                <h3 className='service-head'>Botox</h3>
            <div className='serv-desc'>
                <p className='service-desc'>is a neurotoxic protein produced by the bacterium Clostridium botulinum and related species. It prevents the release of the neurotransmitter acetylcholine from axon endings at the neuromuscular junction, thus causing flaccid paralysis. The toxin causes the disease botulism.</p>
            </div>
                <h3 className='service-head'>PRP</h3>
            <div className='serv-desc'>
                <p className='service-desc'>is an advanced skincare treatment method that harnesses your body’s own natural power to heal itself. PRP stimulates collagen and elastin production which thickens and tightens thinning skin. Effectively smoothing wrinkles and fine lines, great treatment for improving overall skin texture and tone.</p>
            </div>
        </div>

        <br/>
        <br/>
        <hr/>
        <br/>
        <br/>

        <div className='Health'>
            <h2 className='service-title'>Health & Wellness <i class='bx bx-heart'></i></h2>
            <h3 className='service-head'>IV Therapy</h3>
            <div className='serv-desc'>
            <p className="service-desc">is a fast and effective way to administer fluids, vitamins, and minerals to the body. IV drip delivers Vitamins directly into the blood stream, 100% absorption, prevent illness, restore vitamins level, restore your energy.</p>
            </div>

        </div>
    </div>
  )
}

export default Descriptions
