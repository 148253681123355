import { Component } from "react";
import "./NavbarStyles.css";
import { MenuItems } from "./MenuItems";
import { Link } from "react-router-dom"
import HeroImg from '../assets/Pure-Glow-Logo.JPG'

class Navbar extends Component{
    state = {clicked: false};
    handleClick = () =>{
        this.setState({ clicked: !this.state.clicked})
    }
    render(){
        return(
            <nav className="NavbarItems">
                <img alt="HeroImg" className="navbar-logo" src={HeroImg}/>

                <div className="menu-icons" onClick={this.handleClick}>
                    <i className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}></i>
                </div>
                <ul className={this.state.clicked ? "nav-menu active" : "nav-menu"}>
                    {MenuItems.map((item, index) => {
                        return (
                            <li key={index}>
                                <Link className={item.cName} to={item.url}>
                                    {item.title}
                                </Link>
                            </li>
                                )
                    })}
                    <a href="https://www.myaestheticspro.com/booknow/index.cfm?E260E300B4DBF36ACEE51FF5E879E683"><button>Book Appointment</button></a>
                </ul>
            </nav>
        )
    }
}

export default Navbar;