import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import Service from "../components/Service";
import Footer from "../components/Footer";
import Interior from "../assets/Pure-Glow-Logo-removebg-preview.png"

function Home(){
    return(
        <>
            <Navbar />
            <Hero
            cName="hero"
            heroImg={Interior}
            title=""
            text =""
            buttonText="Schedule Appointment"
            url="https://www.myaestheticspro.com/booknow/index.cfm?E260E300B4DBF36ACEE51FF5E879E683"
            btnClass="show"
            />
            <Service/>
            <Footer/>
        </>
    )
}

export default Home;