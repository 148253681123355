import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Services from "../components/Services";
import Hero from "../components/Hero"
import Banner from "../assets/Pure-Glow-Logo-White-Cropped-removebg-preview.png"


function Service (){
    return(
        <>
            <Navbar />
            <Hero
            cName="hero-mid"
            heroImg={Banner}
            title="Pure Glow's Services"
            url="/service"
            />
            <Services/>
            <Footer/>
        </>
    )
}

export default Service;