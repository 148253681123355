import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ContactForm from "../components/ContactForm";
import Location from "../components/Location";
import Banner from "../assets/Pure-Glow-Logo-White-Cropped-removebg-preview.png"

function Contact (){
    return(
        <>
            <Navbar />
            <Hero
            cName="hero-mid"
            heroImg={Banner}
            title="Contact Pure Glow"
            btnClass="hide"
            />
            <ContactForm />
            <Location />
            <Footer/>
        </>
    )
}

export default Contact;