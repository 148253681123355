import "./ServiceStyles.css"

function ServiceData(props) {
    return(
        <div className="t-card">
            <div className="t-image">
                <img src={props.image} alt="image"/>
            </div>
            <h4>{props.heading}</h4>
            <p>{props.text}</p>
            <a href="../service"><button> View Service </button></a>
        </div>
    )
}

export default ServiceData;