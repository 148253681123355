import "./ServiceStyles.css"
import ServiceData from "./ServiceData";
import Service1 from "../assets/skin-treatment-card3.jpeg"
import Service2 from "../assets/body-treatment-card.jpg"
import Service3 from "../assets/pure-inject.jpg"
import Service4 from "../assets/health-wellness.jpg"
function Service(){
    return(
        <div className="service">
            <h1>Our Services</h1>
            <div className="servicecard">
                <ServiceData
                image={Service1}
                heading="Skin Treatment"
                text="Can help you achieve younger looking skin by increasing your skin’s elasticity, improving textural irregularities, tightening facial contours, reducing fine lines and wrinkles."
                url="../routes/Service"
                />

                <ServiceData
                image={Service2}
                heading="Body Treatment"
                text="Advanced equipment and breakthroughs in medical aesthetics there are now several non-invasive surgical treatments such as Skin tightening, muscle toning & body contouring treatments."
                url="/service"

                />

                <ServiceData
                image={Service3}
                heading="Injectables"
                text="Nonsurgical cosmetic treatment are chosen by many individuals as a solution for reducing facial wrinkles and achieving smoother looking skin."
                url="/service"

                />

                <ServiceData
                image={Service4}
                heading="Health & Wellness"
                text="IV vitamin drip is medical treatment part of a popular health and wellness. Administers a high dose of minerals and vitamins directly into your bloodstream allows it to rapidly absorb nutrients at higher doses."
                url="/service"

                />
            </div>
        </div>
    )
}

    export default Service;