import IV from "../assets/health-wellness.jpg"
import body from "../assets/pure-body-c4.jpg"
import injection from "../assets/pure-inject.jpg"
import Service4 from "../assets/health-wellness.jpg"
import ServicesData from "./ServicesData"
import { Link } from "react-router-dom"
import "./ServicesStyles.css"

const Services = () => {
  return (
    <div className='services'>
        <Link to="../services" style={{ textDecoration: 'none', color: 'black'}}><h1>Skin Treatments</h1></Link>

      <ServicesData
      className="first-des"
      heading="Skin Treatments"
      text = "- Microneedling & Microneedling with PRP"
      text2 = "- Morpheus"
      text3 = "- Deluxe Facial"
      text4 = "- Signature Facial"
      text5 = "- Microdermabrasion & Facial"
      text6 = "- Hydro Stamp"
      text7 = "- Plasma Fibroblast"
      img1= "https://media.istockphoto.com/id/1277212329/photo/beautiful-girl-with-wet-hair-posing-against-black-background.jpg?s=612x612&w=0&k=20&c=2sIyfvkyMSV7u2we8w1e5bcch0MFPCWxBiGHt_i7ZsQ="
      />
      <br />
      <br />
      <hr />
      <br />
      <br />

      <Link to="../services" style={{ textDecoration: 'none', color: 'black'}}><h1>Body Treatments</h1></Link>

      <ServicesData
      className="first-des"
      heading="Body Treatments"
      text = "- Cavi Lipo"
      text2 = "- Emsculpt"
      img1={body}
      />
      <br />
      <br />
      <hr />
      <br />
      <br />

      <Link to="../services" style={{ textDecoration: 'none', color: 'black'}}><h1>Injections</h1></Link>
      <ServicesData
      className="first-des"
      heading="Injections"
      text = "- Botox"
      text2 = "- PRP"
      img1={injection}
      />

      <br />
      <br />
      <hr />
      <br />
      <br />


      <Link to="../services" style={{ textDecoration: 'none', color: 'black'}}><h1>Health & Wellness</h1></Link>
          <ServicesData
          className="first-des"
          heading="IV Therapy"
          desc=""
          img1={Service4}
          />
      <br />
      <br />
      <hr />
      <br />
      <br />
    </div>
        )
}

export default Services;