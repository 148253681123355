import "./ContactFormStyles.css"

function ContactForm(){
    return (
        <div className='from-container'>
            <h1>Have questions or concerns? Message Us!</h1>
            <form
                target="_blank"
                action="https://formsubmit.co/a8e5bcf8eda3697720e90601872522b1"
                method="POST"
            >
                <input placeholder='Name' name="name"/>
                <input placeholder='Email' name="email"/>
                <input placeholder='Subject' name="subject"/>
                <textarea placeholder='Message' rows="4" name="message"></textarea>
                <button>Send Message</button>
            </form>
        </div>
    )
}

export default ContactForm;