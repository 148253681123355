export const MenuItems = [
    {
        title : "Home",
        url : "/",
        cName : "nav-links",
    },
    {
        title : "Service",
        url : "/service",
        cName : "nav-links",
    },
    {
        title : "Contact",
        url : "/contact",
        cName : "nav-links",
    },
    {
        title : "Book Appointment",
        url : "https://www.myaestheticspro.com/booknow/index.cfm?E260E300B4DBF36ACEE51FF5E879E683",
        cName : "nav-links-mobile",
    },
]